"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

export function HeaderButton() {
  const path = usePathname();

  const isSignIn = !!path?.includes("sign-in");

  return (
    <Link
      href={isSignIn ? "/sign-up" : "/sign-in"}
      className="btn btn-ghost btn-neutral z-10 rounded-full"
    >
      Sign {isSignIn ? "Up" : "In"}
    </Link>
  );
}
